<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <div class="flow-box">
        <div class="part1 part-box">
          <p>发起人</p>
        </div>
        <i class="leftLine"></i>
        <i class="rightLine"></i>
        <ul class="flow-box-c clearfix">
          <li>
            <div class="part2-2 part-box">
              <p>直接主管审批</p>
              <div class="context" @click="setting(1)">
                <span :class="title1 == '未设置' ? 'noConfig' : ''">{{
                  title1
                }}</span>
              </div>
            </div>
          </li>
          <li>
            <div class="part2-2 part-box">
              <p>无需审批</p>
              <div class="context" @click="setting(0)" data-value="2">
                <span :class="title2 == '未设置' ? 'noConfig' : ''">{{
                  title2
                }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="40%"
      :before-close="handleClose"
    >
      <div slot="title" class="dialog-title clearfix" style="font-size: 16px">
        <span>设置角色</span>
      </div>
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label prop="roleIds">
              <el-checkbox-group v-model="createDate.roleIds">
                <p
                  v-for="item in BaseRolesList"
                  :key="item.id"
                  style="height: 30px"
                >
                  <el-checkbox :label="item.id">{{ item.name }}</el-checkbox>
                </p>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" style="padding: 10px 10px 0">
        <span>
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="save('createDate')"
            >保存</el-button
          >
        </span>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import {
  getApproval,
  setApproval,
  getIsApproval,
  getRoleBaseInfos,
} from "../../api/api";
export default {
  data() {
    return {
      title1: "未设置",
      title2: "未设置",
      dialogVisible: false,
      saveloading: false,
      BaseRolesList: [],
      createDate: {
        isNeed: true,
        roleIds: [],
      },
      rules: {
        roleIds: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    //不能在created方法中初始化
    //this.initG6()
    this.loadList();
    this.loadBaseRoles();
  },
  methods: {
    setting(type) {
      this.createDate.isNeed = type;
      this.dialogVisible = true;
      this.getApproval(this.createDate.isNeed);
    },
    getApproval(type) {
      getApproval({ isNeed: type }).then((res) => {
        if (res.success) {
          this.createDate.roleIds = res.result;
        }
      });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveloading = true;
          setApproval(this.createDate).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.resetForm(formName);
              this.loadList();
              this.dialogVisible = false;
            }
            this.saveloading = false;
          });
        }
      });
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    loadBaseRoles() {
      //获取角色
      getRoleBaseInfos().then((res) => {
        if (res.success) {
          this.BaseRolesList = res.result;
        }
      });
    },
    loadList() {
      getIsApproval().then((res) => {
        if (res.success) {
          if (res.result[1]) {
            this.title1 = "已设置";
          } else {
            this.title1 = "未设置";
          }
          if (res.result[0]) {
            this.title2 = "已设置";
          } else {
            this.title2 = "未设置";
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../common/css/workflow.less";
.reminder-tip {
  height: 40px;
  margin: 0 auto 10px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ffdddd;
  background-color: #fff5f5;
  color: #d99292;
  box-sizing: border-box;
}
.box-title {
  padding: 15px 0;
}
.flow-box .flow-box-c li {
  width: 50%;
}
.flow-box .part1:before {
  height: 50px;
}
.flow-box .part1:after {
  display: none;
}
.flow-box .leftLine {
  width: 230px;
  left: calc(50% - 230px);
}
.flow-box .rightLine {
  width: 230px;
  left: 50%;
}
:deep(.el-dialog__body){
      height: 416px;
    overflow: auto;
}
</style>
